<script setup lang="ts">
import { useCountry } from '@/composables/useCountry';
import { useSalutation } from '@/composables/useSalutation';
import { usePackStation } from '@/composables/usePackStation';

const props = defineProps<{
  useSalutations: boolean;
  useCountries: boolean;
  usePackStation: boolean;
}>();

const attributes = ref({}) as Record<string, any>;

if (props.useSalutations) {
  const { salutations } = useSalutation();
  attributes.value.salutations = salutations;
}

if (props.useCountries) {
  const { countries } = useCountry();
  attributes.value.countries = countries;
}

if (props.usePackStation) {
  const { getConfigs: getPackstationConfig, config: packStationConfig } =
    usePackStation();
  getPackstationConfig();
  attributes.value.packStationConfig = packStationConfig;
}
</script>

<template>
  <slot v-bind="attributes" />
</template>
